import React from "react"
import PropTypes from "prop-types"
import shortid from "shortid"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography,
} from "@material-ui/core"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import Breadcrumbs from "../components/Breadcrumbs"
import SEO from "../components/SEO"

const useStyles = makeStyles(theme => ({
  topWrapper: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 60px)",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 110px)",
    },
  },
  mainImage: {
    height: "100%",
  },
  topOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.4)",
    top: 0,
    left: 0,
  },

  pageTitle: {
    fontSize: 48,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: 700,
    textAlign: "center",
  },

  container: {
    marginTop: 30,
  },

  heading: {
    fontSize: 16,
    fontWeight: 700,
  },
}))

const FaqPage = ({
  data: { faqPage },
  pageContext: { slug, breadcrumbs, locale, alternateLanguages },
  location,
}) => {
  const classes = useStyles()

  const meta = {
    title: faqPage.data.meta_title,
    description: faqPage.data.meta_description,
    keywords: faqPage.data.meta_keywords,
    author: "Turist in Transilvania",
  }

  const schema = faqPage.data.schema_json.text || ""
  const ogp = faqPage.data.ogp_tags || []

  ogp.push({
    property: "og:image",
    content: faqPage.data.image.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: faqPage.data.image.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: faqPage.data.image.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: faqPage.data.image.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const parsedSchema = JSON.parse(schema)

  parsedSchema["@graph"].push({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqPage.data.questions.map(question => ({
      "@type": "Question",
      name: question.question.text,
      acceptedAnswer: {
        "@type": "Answer",
        text: question.answer.text,
      },
    })),
  })

  return (
    <>
      <SEO
        schema={JSON.stringify(parsedSchema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
        alternateLanguages={alternateLanguages}
      />
      <div className={classes.topWrapper}>
        <Img
          alt={faqPage.data.image.alt}
          className={classes.mainImage}
          fluid={faqPage.data.image.fluid}
        />
        <Grid
          direction="row"
          justify="center"
          alignItems="center"
          container
          className={classes.topOverlay}
        >
          <Grid item xs={12}>
            <Typography component="h1" className={classes.pageTitle}>
              {faqPage.data.title.text}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <br />
      <br />
      <Container maxWidth="lg">
        {faqPage.data.questions.map(question => (
          <Accordion key={shortid.generate()}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {question.question.text}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: question.answer.html,
                }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </>
  )
}

export default FaqPage

export const query = graphql`
  query FaqPageQuery($slug: String!, $locale: String!) {
    faqPage: prismicFaqPage(uid: { eq: $slug }, lang: { eq: $locale }) {
      data {
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        meta_title
        meta_description
        meta_keywords
        image {
          alt
          dimensions {
            height
            width
          }
          fluid(maxWidth: 1980, maxHeight: 1070) {
            ...GatsbyPrismicImageFluid
          }
        }

        title {
          text
        }
        questions {
          question {
            text
          }
          answer {
            html
            text
          }
        }
      }
    }
  }
`

FaqPage.propTypes = {
  data: PropTypes.shape({
    faqPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    alternateLanguages: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
